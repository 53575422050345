<template>

    <footer class="site-footer">
            <div class="site-footer-top">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-12">
                            <h2 class="text-white mb-lg-0">HelioPen</h2>
                        </div>

                        <div class="col-lg-6 col-12 d-flex justify-content-lg-end align-items-center">
                            <ul class="social-icon d-flex justify-content-lg-end">
                                <li class="social-icon-item">
                                    <a href="https://twitter.com/HelioPenAi" class="social-icon-link">
                                        <span class="bi-twitter"></span>
                                    </a>
                                </li>
    
                                <li class="social-icon-item">
                                    <a href="https://discord.gg/Cw4Y6PUUQy" class="social-icon-link">
                                        <span class="bi-discord"></span>
                                    </a>
                                </li>
    
                                <!-- <li class="social-icon-item">
                                    <a href="#" class="social-icon-link">
                                        <span class="bi-instagram"></span>
                                    </a>
                                </li>

                                <li class="social-icon-item">
                                    <a href="#" class="social-icon-link">
                                        <span class="bi-youtube"></span>
                                    </a>
                                </li> -->

                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">

                    <div class="col-lg-6 col-12 mb-4 pb-2">
                        <h5 class="site-footer-title mb-3">Links</h5>

                        <li class="site-footer-link-item">
                                <router-link to="/#section_1" class="site-footer-link"  data-aos="fade-up" data-aos-delay="50">Home</router-link>
                            </li>
    
                            <li class="site-footer-link-item">
                                <router-link to="/#section_2"  class="site-footer-link" data-aos="fade-up" data-aos-delay="100">Content</router-link>
                            </li>
    
                            <li class="site-footer-link-item">
                                 <router-link to="/#section_3"  class="site-footer-link" data-aos="fade-up" data-aos-delay="150">Pass</router-link>
                            </li>

                            <li class="site-footer-link-item">
                                 <router-link to="/#section_4"  class="site-footer-link" data-aos="fade-up" data-aos-delay="200"> FAQs</router-link>
                            </li>

                        
                    </div>

                    <div class="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                        <h5 class="site-footer-title mb-3">Have a question?</h5>

                        <p class="text-white d-flex">
                            <a href="mailto:info@HelioPen.ai" class="site-footer-link">
                                info@HelioPen.ai
                            </a>
                        </p>
                    </div>

                   
                </div>
            </div>

            <div class="site-footer-bottom">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-3 col-12 mt-5">
                            <p class="copyright-text">Copyright © 2023 HelioPen</p>
                        </div>
                        <div class="col-lg-3 col-12 mt-lg-5">
                            <ul class="site-footer-links">
                                <li class="site-footer-link-item">
                                    <a href="/terms" class="site-footer-link">Terms</a>
                                </li>

                                <li class="site-footer-link-item">
                                    <a href="/privacy" class="site-footer-link">Privacy Policy</a>
                                </li>

                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
    </footer>

</template>

<script>

export default {
  name: 'AppFooter',

};



</script>
