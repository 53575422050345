<template>
      <main>
            <nav class="navbar navbar-expand-lg" style="width: 100%; position: fixed; top: 0px;">
                <div class="container">
                    <span class="navbar-brand" data-aos="fade-up" data-aos-delay="50">
  <img
    src="images/logo.png"
    alt="HelioPen Logo"
    height="40" 
  />
  HelioPen
</span>
                  

                
                    <router-link to="/secondary" class="btn custom-btn d-lg-none ms-auto me-4" data-aos="fade-up" data-aos-delay="100">Buy Pass</router-link>
                  
    
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
    
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav align-items-lg-center ms-auto me-lg-5">
                            <li class="nav-item">
                                <router-link to="/#section_1" class="nav-link click-scroll"  data-aos="fade-up" data-aos-delay="50">Home</router-link>
                            </li>
    
                            <li class="nav-item">
                                <router-link to="/#section_2"  class="nav-link click-scroll" data-aos="fade-up" data-aos-delay="100">Content</router-link>
                            </li>
    
                            <li class="nav-item">
                                 <router-link to="/#section_3"  class="nav-link click-scroll" data-aos="fade-up" data-aos-delay="150">Pass</router-link>
                            </li>

                            <li class="nav-item">
                                 <router-link to="/#section_4"  class="nav-link click-scroll" data-aos="fade-up" data-aos-delay="200"> FAQs</router-link>
                            </li>
                        </ul>
                        <router-link to="/secondary"  class="btn custom-btn d-lg-block d-none" data-aos="fade-up" data-aos-delay="350">Mint Pass</router-link>
                           
                            
                            
                           
                    </div>
                </div>
            </nav>
            

           
        </main>


        
</template>

<script>

export default {
  name: 'AppHeader',
};

</script>


<style>
.navbar-brand img {
  filter: invert(1);  
}
</style>


