<template>
  <section class="ticket-section section-padding bg-dark text-white" id="section_pay">
    <div class="section-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-12 mx-auto">
          <form class="custom-form ticket-form mb-5 mb-lg-0" action="#" method="post" role="form">
                    <h2 class="text-center mb-5 display-4">HelioPen PASS</h2>
        <div class="ticket-form-body">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-6 col-md-6 col-12 text-center">
              <span class="h2 col-lg-6 col-6 text-warning" style="font-size: 24px;">{{ mintPrice }} ETH/NFT</span>  
            </div>
          </div>

          <div class="row justify-content-center">
  <div class="col-lg-6 col-md-6 col-12 text-center">
    <div class="input-group custom-input-group align-items-center">
      <div class="input-group-prepend">
        <button type="button" class="btn btn-outline-primary" @click="decreaseQuantity">
          <font-awesome-icon icon="fa-solid fa-thumbs-down" />
        </button>
      </div>
      <input type="number" v-model="nftAmount" min="1" max="20" class="form-control text-center" placeholder="Quantity" required />
      <div class="input-group-append">
        <button type="button" class="btn btn-outline-primary" @click="increaseQuantity">
          <font-awesome-icon icon="fa-solid fa-thumbs-up" />
        </button>
      </div>
    </div>
  </div>
</div>
          <div v-if="walletConnected" class="mb-4">
            <h6>Connected Wallet: </h6> 
            <p>{{ userAddress }}</p>
            <p>Your NFT Balance: {{ nftBalance }}</p>
            <p>Available NFTs for purchase: {{ remainingNFTs }}</p>
          </div>
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-warning btn-lg mb-3" @click="connectWallet">Connect Wallet</button>
            <button type="button" class="btn btn-primary btn-lg" @click="mintNFT">Mint NFT</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
  </section>
</template>



<script>
// eslint-disable-next-line no-unused-vars
import Web3 from "web3";

export default {
  data() {
    return {
      web3: null,
      nftAmount: 1,
      walletConnected: false,
      nftBalance: 0,
      remainingNFTs: 0,
      userAddress: null,
      mintPrice: 0.15,
      ownedNFTs: [],
      // eslint-disable-next-line no-unused-vars
      contractAddress: "0xE1D8963e2d7ce742f2097fa2E93637E26f55AA57",
      contractABI: [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_PURCHASE",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "numTokens",
				"type": "uint256"
			}
		],
		"name": "mintMultiple",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mintPrice",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "saleIsActive",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "newBaseTokenURI",
				"type": "string"
			}
		],
		"name": "setBaseTokenURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "newPrice",
				"type": "uint256"
			}
		],
		"name": "setMintPrice",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "newState",
				"type": "bool"
			}
		],
		"name": "setSaleState",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "_tokenURI",
				"type": "string"
			}
		],
		"name": "setTokenURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
],
    };
  },
  async mounted() {
    await this.loadWeb3();
    await this.fetchMintPrice();
  },
  methods: {
    async loadWeb3() {
      if (window.ethereum) {
        this.web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        this.contract = new this.web3.eth.Contract(this.contractABI, this.contractAddress);
        return true;
      } else {
        alert("Please install MetaMask!");
        return false;
      }
    },
    async fetchMintPrice() {
      try {
        const price = await this.contract.methods.mintPrice().call();
		if(price === null || price === undefined){
        this.mintPrice = this.web3.utils.fromWei(this.mintPrice, "ether");
	} else {
		this.mintPrice = this.web3.utils.fromWei(price, "ether");
		}
		
      } catch (error) {
        console.error("Error fetching mint price:", error);
      }
    },
    async connectWallet() {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        this.walletConnected = true;
        this.userAddress = (await this.web3.eth.getAccounts())[0];
        await this.getNFTBalance();
        await this.getRemainingNFTs();
      } catch (error) {
        console.error('User rejected connection:', error);
      }
    } else {
      alert('Please install MetaMask or another supported wallet.');
    }
  },


  async mintNFT() {
    if (!this.walletConnected) {
      await this.connectWallet();
    }

    const contract = new this.web3.eth.Contract(this.contractABI, this.contractAddress);
    const ethTotalCost = (this.mintPrice * this.nftAmount).toFixed(18);
    const weiTotalCost = this.web3.utils.toWei(ethTotalCost.toString(), "ether");

    contract.methods
      .mintMultiple(this.nftAmount)
      .send({ from: this.userAddress, value: weiTotalCost })
      .on('transactionHash', (hash) => {
        console.log('Transaction hash:', hash);
      })
      .on('receipt', async (receipt) => {
        console.log('Transaction receipt:', receipt);
        await this.getNFTBalance();
        await this.getRemainingNFTs();
      })
      .on('error', (error) => {
        console.error('Transaction error:', error);
      });
  },
  async getNFTBalance() {
    const contract = new this.web3.eth.Contract(this.contractABI, this.contractAddress);
    this.nftBalance = await contract.methods.balanceOf(this.userAddress).call();
  },
  async getRemainingNFTs() {
    const contract = new this.web3.eth.Contract(this.contractABI, this.contractAddress);
    const totalSupply = await contract.methods.totalSupply().call();
    this.remainingNFTs = 10000 - totalSupply;
  },
  async displayOwnedNFTs() {
      const numOwned = await this.contract.methods.balanceOf(this.userAddress).call();
      this.ownedNFTs = [];
      for (let i = 0; i < numOwned; i++) {
        const tokenId = await this.contract.methods.tokenOfOwnerByIndex(this.userAddress, i).call();
        const tokenURI = await this.contract.methods.tokenURI(tokenId).call();
        this.ownedNFTs.push({ tokenId, tokenURI });
      }
    },
    increaseQuantity() {
      if (this.nftAmount < 20) {
        this.nftAmount++;
      }
    },
    decreaseQuantity() {
      if (this.nftAmount > 1) {
        this.nftAmount--;
      }
    }
}
  
};
</script>

<style scoped>

.red-text {
color: #ed3c3c;
}

.btn {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}

 .custom-input-group .btn {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

</style>
