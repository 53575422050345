export default {
    beforeMount(el, binding) {
      el.addEventListener("click", (event) => {
        event.preventDefault();
  
        const targetElement = document.querySelector(binding.value);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
  };
  