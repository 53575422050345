<template>
    
 <section class="slice--offset sct-color-1" style="padding-top: 100px;">
                        <div class="container container-xs">
                            <div class="text-center">
                                <h3 class="text-center mb-5 aos-init aos-animate">
                                    Terms of Use
                                </h3>
                            </div>

                            <div class="block block-post">
                                <div class="block-body block-post-body">





<p>Last updated: March 20, 2023</p>

<p>HelioPen, Inc. (“HelioPen,” “we,” “us,” or “our”) provides its services and related content to you (“you” or “User”) through its website and platform located at www.HelioPen.xyz (the “Site”), subject to these Terms of Use (as amended from time to time, the “Terms”).  The Privacy Policy and all such additional terms, guidelines, and rules as set forth on the Site are hereby incorporated by reference into these Terms and expressly agreed to and acknowledged by you.  These terms govern your access to and use of this Site, as well as all content, functionality, and services offered on or through the Site, including Perks (as defined below) (collectively, the “Services”), and HelioPen Pass NFTs.  By signing up for an account on the Site, connecting your cryptocurrency wallet (e.g., MetaMask or WalletConnect) to the Services, or otherwise using or accessing the Services, Perks or any HelioPen Pass NFTs, you acknowledge that you have read and agree to these Terms.  </p>

<p>By agreeing to these Terms, you hereby certify that you are at least 18 years of age.  If you do not agree to these Terms, you must not access or use the Site.</p>

<p>PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS.  THE AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST HelioPen ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW.</p>

<p>We reserve the right, at our sole discretion, to change or modify portions of these Terms at any time.  If we do this, we will post the changes on this page and will indicate at the top of this page the date these Terms were last revised.  We will also notify you, either through the Site user interface, in an email notification or through other reasonable means.  Any such changes will become effective no earlier than fourteen (14) days after they are posted, except that changes addressing new functions of the Site will be effective immediately.  Your continued use of the Site after the date any such changes become effective constitutes your acceptance of the new Terms of Use.</p>

<ol>

<li><p><strong>SMART CONTRACT ENABLED </strong><br>
A HelioPen Pass NFT is a non-fungible Ethereum-based token that uses smart contracts on the Ethereum blockchain (“Smart Contracts”).  The Ethereum blockchain provides an immutable ledger of all transactions that occur on the blockchain.  This means that all HelioPen Pass NFTs are outside of the control of any one party, including HelioPen, and are subject to many risks and uncertainties.  HelioPen neither owns nor controls MetaMask, WalletConnect, the Ethereum network, your browser, or any other third party site, product, or service (including third party wallets or marketplaces) that you might access, visit, or use for the purpose of enabling you to use the Services or to purchase, list, auction, or sell HelioPen Pass NFTs (“Transaction”), and that, except with respect to transferring control of a HelioPen Pass NFT to the initial purchaser through the Services (“Initial Purchaser”), HelioPen has no responsibility with respect to any Transaction.  HelioPen will not be liable for the acts or omissions of any third parties, nor will HelioPen be liable for any damage that you may suffer as a result of your transactions or any other interaction with any third parties.  You understand that your Ethereum public address will be made publicly visible whenever you engage in a Transaction.  Aside from transferring control of the HelioPen Pass NFT to the Initial Purchaser, HelioPen has no control over the transfer, storage, ownership or maintenance of the HelioPen Pass NFT.</p></li>

<li><p><strong>CHANGES TO THE SERVICES </strong><br>
HelioPen reserves the right to modify or discontinue, temporarily or permanently, the Services (or any part thereof) with or without notice.  You agree that HelioPen will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Services, including in relation to any HelioPen Pass NFT.  </p></li>

<li><p><strong>HelioPen PERKS</strong><br>
A Collector (as defined below) of a HelioPen Pass NFT may receive special perks, experiences or opportunities as determined by HelioPen in its sole discretion (“Perks”) during the calendar year associated with the applicable HelioPen Pass NFT.  HelioPen does not make any representation or guarantee that any Collector will receive any Perks or achieve any particular outcome as a result of owning any HelioPen Pass NFT.  HelioPen reserves the right, at its sole discretion, to restrict, limit, or deny any Perks to any Collector, including to limit the period of time when a Perk is available, for any reason, at any time.  Perks are not intended to be an endorsement of any project, creator, NFT, individual or any other thing, and HelioPen makes no recommendation and provides no investment advice in connection with any Perks or otherwise as a result of holding or owning the HelioPen Pass NFT.</p></li>

<li><p><strong>MEMBER ACCOUNT, PASSWORD, AND SECURITY</strong><br>
You are responsible for maintaining the confidentiality of your account and password, if any, and are fully responsible for any and all activities that occur under your password or account.  You agree to (a) immediately notify HelioPen of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session when accessing the Services.  HelioPen will not be liable for any loss or damage arising from your failure to comply with this Section.</p></li>

<li><p><strong>CONNECTING YOUR WALLET</strong><br>
In order to access and use the Services, including engaging in a Transaction on the Services, you must connect your account to your digital wallet supported on MetaMask, WalletConnect or other wallet extensions or gateways as allowed on the Services.  Such digital wallets allow you to purchase, store, and engage in transactions using the native Ethereum cryptocurrency, ETH.  When you link your cryptocurrency wallet, you understand and agree that you are solely responsible for maintaining the security of your wallet and your control over any wallet-related authentication credentials, private or public cryptocurrency keys, non-fungible tokens or cryptocurrencies that are stored in or are accessible through your wallet.  Any unauthorized access to your cryptocurrency wallet by third parties could result in the loss or theft of HelioPen Pass NFTs and/or funds held in your wallet and any associated wallets, including any linked financial information such as bank account(s) or credit card(s).  HelioPen is not responsible for managing and maintaining the security of your cryptocurrency wallet.  HelioPen has no responsibility or liability to you for any unauthorized access to or use of your cryptocurrency wallet or if you are unable to locate your credentials.  If you notice any unauthorized or suspicious activity in your cryptocurrency wallet that seems to be related to the Services, please notify us immediately.</p></li>

<li><p><strong>SALE OF HelioPen Pass NFT</strong><br>
When each HelioPen Pass NFT is sold for the first time, the agreement for sale is between HelioPen and the Initial Purchaser.  If the Initial Purchaser decides to sell a HelioPen Pass NFT (“Secondary Sale”), then HelioPen is not a party to any agreement between the applicable buyer, seller, or facilitator of the Secondary Sale. <br><br>

If you or any owner of a HelioPen Pass NFT (“Collector”) sells or transfers a HelioPen Pass NFT to another collector, (a) then you, as Collector, represents and warrants that you will notify the subsequent collector of these Terms and require the subsequent collector to comply with these Terms, (b) the Collector License (as defined below) as set forth herein will automatically transfer to such subsequent collector, and such other collector will be deemed the “Collector” (for purposes of such HelioPen Pass NFT and the Collector License to the underlying NFT IP (as defined below)) and will be subject to these Terms, and (c) you, as the seller or transferor of such HelioPen Pass NFT, will cease to have any further rights to such HelioPen Pass NFT or underlying NFT IP. <br><br>

By placing an order on the Site or through the Services (including by bidding in an auction), you agree that you are submitting a binding offer to purchase a HelioPen Pass NFT, you agree to pay all applicable fees associated with the Transaction, and you authorize HelioPen to automatically charge and collect such fees from your payment instrument or wallet.  If you are an Initial Purchaser, then all amounts due are to be paid to HelioPen.  If you are not the Initial Purchaser of a HelioPen Pass NFT, then amounts may be paid to the seller of such HelioPen Pass NFT.  <br><br>

You acknowledge and agree that HelioPen receives 7.5% of every Secondary Sale of a HelioPen Pass NFT (“Royalty”).  HelioPen has the right to collect Royalties for HelioPen Pass NFT sales in perpetuity.  As such, if you sell a HelioPen Pass NFT on a third-party marketplace, you agree to include a statement substantially similar to the following in the description of the NFT: <br>
“7.5% Royalty Applies. By purchasing a HelioPen Pass NFT, you agree to the Terms of Use located at https://www.HelioPen.xyz/terms/." <br><br>

No refunds are permitted except with respect to any statutory warranties or guaranties that cannot be excluded or limited by law.</p></li>

<li><p><strong>TAXES</strong><br>
You are responsible for any and all sales, use, value-added and other taxes, duties, and assessments now or hereafter claimed or imposed by any governmental authority, associated with your use of the Services, Perks or HelioPen Pass NFTs (including, without limitation, any taxes that may become payable as the result of your ownership, transfer, purchase, or sale of a HelioPen Pass NFT).</p></li>

<li><p><strong>HelioPen INTELLECTUAL PROPERTY RIGHTS</strong><br>
You acknowledge and agree that the Services may contain content or features (“Services Content”) that are protected by copyright, patent, trademark, trade secret, or other proprietary rights and laws.  Except as expressly authorized by HelioPen, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute, or create derivative works based on the Services or the Services Content, in whole or in part.  Any use of the Services or the Services Content other than as specifically authorized herein is strictly prohibited. <br><br>

HelioPen’s name and logos are trademarks and service marks of HelioPen (collectively the “HelioPen Trademarks”).  Other company, product, and service names and logos used and displayed via the Services may be trademarks or service marks of their respective owners who may or may not endorse or be affiliated with or connected to HelioPen.  Nothing in these Terms or the Services should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of HelioPen Trademarks displayed on the Services, without HelioPen’s prior written permission in each instance.  All goodwill generated from the use of HelioPen Trademarks will inure to our exclusive benefit.</p></li>

<li><p><strong>YOUR INTELLECTUAL PROPERTY RIGHTS</strong><br>
You acknowledge and agree that HelioPen (or, as applicable, its licensors) owns all legal rights, title and interest in all intellectual property rights of the content underlying HelioPen Pass NFTs (“NFT IP”), including but not limited to copyrights and trademarks in the NFT IP.  As the copyright owner, HelioPen has the exclusive right to reproduce, prepare derivatives of, distribute, display, perform and otherwise exercise and exploit the NFT IP, subject to Collector Licenses. <br><br>

Subject to continued compliance with these Terms, HelioPen grants Collector a limited, worldwide, non-assignable and non-transferable (except as part of a Secondary Sale), non-sublicensable, royalty-free license to display the NFT IP solely for the Collector’s non-commercial purposes (except as expressly permitted herein), including the right to display such NFT IP privately or publicly: (i) for the purpose of promoting or sharing the Collector’s purchase of, ownership of, or interest in such HelioPen Pass NFT, (ii) for the purpose of sharing, promoting, discussing, or commenting on such HelioPen Pass NFT or NFT IP; (iii) on third party marketplaces, exchanges, platforms, or applications in association with a Transaction of the HelioPen Pass NFT; and (iv) within decentralized virtual environments, virtual worlds, virtual galleries, virtual museums, or other navigable and perceivable virtual environments (the “Collector License”).  While the Collector has the right to sell, trade, transfer, or use their HelioPen Pass NFT, the Collector may not make commercial use of the NFT IP.</p></li>

<li><p><strong>No Professional Advice; Transaction Risks</strong> <br><br>

The information on this Site and any information provided in connection with the Services or Perks are provided to HelioPen Pass NFT owners only and for information only and do not constitute, and should not be construed as, professional advice or a recommendation to purchase, sell, trade or otherwise transact in any digital asset, including any products or services, or an invitation, offer or solicitation to engage in any purchase, sale, trade or other transaction with respect to any digital asset. <br><br>

The information on this Site and any information provided in connection with the Services or Perks provided to HelioPen Pass NFT owners are provided solely on the basis that you will make your own transaction decisions, and HelioPen does not take account of any person’s financial or other objectives, particular needs, or financial situation.  In addition, nothing on this Site or any information provided in connection with the Services or Perks provided to HelioPen Pass NFT owners shall, or is intended to, constitute financial, legal, accounting or tax advice.  It is strongly recommended that you seek professional advice before making any transaction decision.  Any decision that you make should be based on an assessment of your risks in consultation with your professional adviser(s).   <br><br>

The digital assets about which information is provided on the Site and any information provided in connection with the Services or Perks provided to HelioPen Pass NFT owners are not viewed by the issuer or sponsor of any such digital assets, or those buying or selling the digital asset, as securities under U.S. laws or relevant applicable laws.  As a result it is unlikely that fulsome disclosures from the issuer or sponsor, or any executive officer associated with the digital asset or related protocol have been provided, and others may have better or more information than the information made available to you via the Site or any information provided in connection with the Services or Perks provided to HelioPen Pass NFT owners, or to which you may independently have access.     <br><br>

There are risks associated with purchasing and holding digital assets.  Loss of the full amount of the purchase price is possible.  Volatility is highly likely, and some of the protocols and platforms may fail entirely due to forking, flaws in the code, hacking or other malicious attacks. <br><br>

THESE TERMS DO NOT REPRESENT A COMPLETE STATEMENT OF RISK FACTORS ASSOCIATED WITH THE DIGITAL ASSETS OR PRODUCTS OR SERVICES THAT MAY BE VIEWED OR TRACKED ON THIS SITE OR THAT MAY BE FEATURED OR DISCUSSED IN CONNECTION WITH THE SERVICES OR PERKS FOR HelioPen Pass NFT OWNERS.  YOU SHOULD CONSIDER THESE RISK WARNINGS CAREFULLY AND TAKE APPROPRIATE ADVICE BEFORE TAKING ANY DECISION TO PURCHASE, SELL, TRADE OR OTHERWISE TRANSACT IN A DIGITAL ASSET.</p>
</li>
<li><p><strong>RESTRICTIONS</strong></p><br>
You agree that you will not, and will not permit any third party to, do or attempt to do any of the following without the HelioPen’s express prior written consent in each case:
</li>
<ol type="a">

<li><p>interfere with or disrupt the Services or servers or networks connected to the Services in any manner that could negatively affect or inhibit other Users from fully enjoying the Services or that could damage, disable, overburden or impair the functioning of the Services in any manner;</p></li>
<li><p>violate any applicable local, state, national or international law, or any regulations having the force of law, including but not limited to the U.S. Department of Treasury’s Office of Foreign Assets Control (“OFAC”), or which would involve proceeds of any unlawful activity;</p></li>
<li><p>obtain or attempt to access or otherwise obtain any materials or information through any means not intentionally made available or provided for through the Services;</p></li>
<li><p>use the Services or any HelioPen Pass NFT or NFT IP to advertise or offer to sell or buy any goods or services for any purpose that is not specifically authorized herein;</p></li>
<li><p>use the Services or any HelioPen Pass NFT or NFT IP to further or promote any criminal activity or enterprise or provide instructional information about illegal activities, including for the purpose of concealing economic activity, laundering money, or financing terrorism; or</p></li>
<li><p>use the Services or any HelioPen Pass NFT or NFT IP to carry out financial activities subject to registration or licensing, including but not limited to creating, listing, or buying securities, commodities, options, real estate, or debt instruments.</p></li>
</ol>


<li><p><strong>PRIVACY</strong><br>
Our Privacy Policy is a part of these Terms.  Please review the Privacy Policy, which also governs the Services and informs Users of our data collection practices.</p></li>

<li><p><strong>RISKS</strong><br>
Please note the following risks in accessing, purchasing, selling or using HelioPen Pass NFTs: The price and liquidity of blockchain assets, including HelioPen Pass NFTs, are extremely volatile and may be subject to large fluctuations.  Fluctuations in the price of other digital assets could materially and adversely affect HelioPen Pass NFTs, which may also be subject to significant price volatility.  Legislative and regulatory changes or actions at the state, federal, or international level may adversely affect the use, transfer, exchange, and value of HelioPen Pass NFTs.  HelioPen Pass NFTs are not legal tender and are not backed by any government.  Transactions of HelioPen Pass NFTs may be irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable.  Some Transactions of HelioPen Pass NFTs shall be deemed to be made when recorded on a public ledger, which is not necessarily the date or time that you initiated the Transaction.  The value of HelioPen Pass NFTs may be derived from the continued willingness of market participants to exchange fiat currency or digital assets for HelioPen Pass NFTs, which may result in the potential for permanent and total loss of value of a particular HelioPen Pass NFT.<br><br>

You agree and understand that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself, and that we do not give advice or recommendations regarding HelioPen Pass NFTs, including the suitability and appropriateness of, and investment strategies for, HelioPen Pass NFTs.  You agree and understand that you access and use the Services, HelioPen Pass NFTs, NFT IP, and Perks at your own risk; however, this brief statement does not disclose all of the risks associated with HelioPen Pass NFTs and other digital assets.  You agree and understand that HelioPen will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using HelioPen Pass NFTs, however caused.</p></li>

<li><p><strong>DISCLAIMER OF WARRANTIES</strong><br>
YOUR USE OF THE SERVICES, HelioPen Pass NFTs, NFT IP AND PERKS IS AT YOUR SOLE RISK.  EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY HelioPen, THE SERVICES, PERKS, SERVICES CONTENT, AND ANY AND ALL HelioPen Pass NFTs AND NFT IP ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. <br><br>

HelioPen AND ITS AFFILIATES AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, REPRESENTATIVES, DIRECTORS AND AGENTS (COLLECTIVELY, “REPRESENTATIVES”) MAKE NO WARRANTY THAT (I) THE SERVICES, HelioPen Pass NFTS, NFT IP OR PERKS WILL MEET YOUR REQUIREMENTS, (II) THE SERVICES, HelioPen Pass NFTS, NFT IP OR PERKS WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES, HelioPen Pass NFTS, NFT IP OR PERKS WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY SERVICES, HelioPen Pass NFTS, NFT IP OR PERKS, OR ANY PRODUCTS, PLATFORMS, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES, HelioPen Pass NFTS OR PERKS WILL MEET YOUR EXPECTATIONS.<br><br>

HelioPen AND ITS REPRESENTATIVES WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR TAKEN IN RELIANCE ON MATERIAL OR INFORMATION, CONTAINED ON THE SERVICES, HelioPen Pass NFTS, NFT IP OR PERKS.  HelioPen DOES NOT REPRESENT OR WARRANT THAT SERVICES CONTENT IS ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE.<br><br>

HelioPen Pass NFTS ARE INTANGIBLE DIGITAL ASSETS.  THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM NETWORK.  ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE ETHEREUM PLATFORM.  WE DO NOT GUARANTEE THAT HelioPen CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY HelioPen Pass NFT.</p></li>

<li><p><strong>LIMITATION OF LIABILITY</strong><br>
TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL HelioPen OR ITS REPRESENTATIVES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THESE TERMS, THE SITE, THE SERVICES, SERVICES CONTENT, HelioPen Pass NFTS, NFT IP, PERKS OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  ACCESS TO, AND USE OF, THE SITE, THE SERVICES, SERVICES CONTENT, HelioPen Pass NFT, NFT IP, PERKS OR THIRD PARTY SITES AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA RESULTING THEREFROM.<br><br>

NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF HelioPen OR ANY OF ITS REPRESENTATIVES ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS, THE ACCESS TO AND USE OF THE SITE, THE SERVICES, SERVICES CONTENT, HelioPen Pass NFT, NFT IP OR PERKS EXCEED $100.<br><br>

SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.  ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU.  IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICES, HelioPen Pass NFTS, NFT IP OR PERKS OR WITH THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICES.<br><br>

IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED “DISCLAIMER OF WARRANTIES” AND “LIMITATION OF LIABILITY”, AND THE SECTION ENTITLED “INDEMNIFICATION” BELOW, ARE INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY.  IF ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION SHALL NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.</p></li>

<li><p><strong>INDEMNIFICATION</strong><br>
To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold harmless HelioPen and its Representatives (collectively, the “Indemnitees”), from and against all actual or alleged third party claims, damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses) and costs (including, without limitation, court costs, costs of settlement and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to (a) your use or misuse of the Site, the Services, Services Content, HelioPen Pass NFTs, NFT IP or Perks, (b) your violation of these Terms, or (c) your violation of the rights of a third party, including another User.  You agree to promptly notify HelioPen of any third party Claims and cooperate with the applicable Indemnitee in defending such Claims.  You further agree that the Indemnitees shall have the right to control of the defense or settlement of any third party Claims.  THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND US.</p></li>

<li><p><strong>TERMINATION RIGHTS</strong><br>
You agree that HelioPen, in its sole discretion, may suspend or terminate your account (or any part thereof) or use of the Services or Perks and remove and discard any content within the Services, for any reason, including, without limitation, for lack of use or if HelioPen believes that you have violated or acted inconsistently with the letter or spirit of these Terms.  Any suspected fraudulent, abusive or illegal activity that may be grounds for termination of your use of the Services or Perks may be referred to appropriate law enforcement authorities.  HelioPen may also in its sole discretion and at any time discontinue providing the Services or Perks, or any part thereof, with or without notice.  You agree that any termination of your access to the Services or Perks under any provision of these Terms may be effected without prior notice, and acknowledge and agree that HelioPen may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Services or Perks.  Further, you agree that HelioPen will not be liable to you or any third party for any termination of your access to the Services or Perks.</p></li>

<li><p><strong>DISPUTES WITH OTHER USERS OR COLLECTORS</strong><br>
You agree that you are solely responsible for your interactions with any other Users and Collectors in connection with the Services, HelioPen Pass NFTs and Perks, and HelioPen will have no liability or responsibility with respect thereto.  HelioPen reserves the right, but has no obligation, to become involved in any way with disputes between you and any other User or Collector.</p></li>

<li><p><strong>DISPUTE RESOLUTION BY BINDING ARBITRATION</strong><br>
PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.
<ol type="a">
<li><p><strong>Agreement to Arbitrate</strong><br>
This Dispute Resolution by Binding Arbitration section is referred to in these Terms as the “Arbitration Agreement.” You agree that any and all disputes or claims that have arisen or may arise between you and HelioPen, whether arising out of or relating to these Terms (including any alleged breach thereof), the Services, HelioPen Pass NFTs, Perks, any advertising, or any aspect of the relationship or transactions between us, will be resolved exclusively through final and binding arbitration, rather than a court, in accordance with the terms of this Arbitration Agreement, except that you may assert individual claims in small claims court, if your claims qualify.  Further, this Arbitration Agreement does not preclude you from bringing issues to the attention of federal, state, or local agencies, and such agencies can, if the law allows, seek relief against us on your behalf.  You agree that, by entering into these Terms, you and HelioPen are each waiving the right to a trial by jury or to participate in a class action.  Your rights will be determined by a neutral arbitrator, not a judge or jury.  The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement.</p></li>
<li><p><strong>Prohibition of Class and Representative Actions and Non-Individualized Relief</strong><br>

YOU AND HelioPen AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.  UNLESS BOTH YOU AND HelioPen AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING.  ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S), EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT REQUIRED FOR THE ENFORCEABILITY OF THIS PROVISION.
</p></li>

<li><p><strong>Pre-Arbitration Dispute Resolution</strong> <br>
HelioPen is always interested in resolving disputes amicably and efficiently, and most customer concerns can be resolved quickly and to the customer’s satisfaction by emailing HelioPen at<a href="mailto:info@HelioPen.ai" class="text-dark">info@HelioPen.ai</a>If such efforts prove unsuccessful, a party who intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute (“Notice”).  The Notice to HelioPen should be sent to HelioPen, Inc, 249 S Highway 101 #220, Solana Beach, CA 92075 (“Notice Address”).  The Notice must (i) describe the nature and basis of the claim or dispute and (ii) set forth the specific relief sought.  If HelioPen and you do not resolve the claim within sixty (60) calendar days after the Notice is received, you or HelioPen may commence an arbitration proceeding.  During the arbitration, the amount of any settlement offer made by HelioPen or you will not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or HelioPen is entitled.</p></li>
<li><p><strong>Arbitration Procedures</strong><br>
Arbitration will be conducted by a neutral arbitrator in accordance with the American Arbitration Association’s (“AAA”) rules and procedures, including the AAA’s Consumer Arbitration Rules (collectively, the “AAA Rules”), as modified by this Arbitration Agreement.  For information on the AAA, please visit its website, http://www.adr.org.  Information about the AAA Rules and fees for consumer disputes can be found at the AAA’s consumer arbitration page, https://www.adr.org/consumer.  If there is any inconsistency between any term of the AAA Rules and any term of this Arbitration Agreement, the applicable terms of this Arbitration Agreement will control unless the arbitrator determines that the application of the inconsistent Arbitration Agreement terms would not result in a fundamentally fair arbitration.  The arbitrator must also follow the provisions of these Terms as a court would.  All issues are for the arbitrator to decide, including issues relating to the scope, enforceability, and arbitrability of this Arbitration Agreement.  Although arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings, the arbitrator can award the same damages and relief on an individual basis that a court can award to an individual under these Terms and applicable law.  Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons. <br><br>

Unless HelioPen and you agree otherwise, any arbitration hearings will take place in a reasonably convenient location for both parties with due consideration of their ability to travel and other pertinent circumstances.  If the parties are unable to agree on a location, the determination will be made by AAA.  If your claim is for $10,000 or less, HelioPen agrees that you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic hearing, or by an in-person hearing as established by the AAA Rules.  If your claim exceeds $10,000, the right to a hearing will be determined by the AAA Rules.  Regardless of the manner in which the arbitration is conducted, the arbitrator will issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the award is based.</p></li>
<li><p><strong>Costs of Arbitration</strong><br>
Payment of all filing, administration, and arbitrator fees (collectively, the “Arbitration Fees”) will be governed by the AAA Rules, unless otherwise provided in this Arbitration Agreement.  To the extent any Arbitration Fees are not specifically allocated to either HelioPen or you under the AAA Rules, HelioPen and you shall split them equally; provided that if you are able to demonstrate to the arbitrator that you are economically unable to pay your portion of such Arbitration Fees or if the arbitrator otherwise determines for any reason that you should not be required to pay your portion of any Arbitration Fees, HelioPen will pay your portion of such fees. In addition, if you demonstrate to the arbitrator that the costs of arbitration will be prohibitive as compared to the costs of litigation, HelioPen will pay as much of the Arbitration Fees as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive.  Any payment of attorneys’ fees will be governed by the AAA Rules.</p></li>
<li><p><strong>Confidentiality</strong><br>
All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will be strictly confidential for the benefit of all parties.</p></li>
<li><p><strong>Severability</strong><br>
If a court or the arbitrator decides that any term or provision of this Arbitration Agreement (other than the subsection (b) above titled “Prohibition of Class and Representative Actions and Non-Individualized Relief” above) is invalid or unenforceable, the parties agree to replace such term or provision with a term or provision that is valid and enforceable and that comes closest to expressing the intention of the invalid or unenforceable term or provision, and this Arbitration Agreement will be enforceable as so modified.  If a court or the arbitrator decides that any of the provisions of subsection (b) above titled “Prohibition of Class and Representative Actions and Non-Individualized Relief” are invalid or unenforceable, then the entirety of this Arbitration Agreement will be null and void, unless such provisions are deemed to be invalid or unenforceable solely with respect to claims for public injunctive relief.  The remainder of these Terms will continue to apply.
</p></li>
<li><p><strong>Future Changes to Arbitration Agreement</strong><br>
Notwithstanding any provision in these Terms to the contrary, HelioPen agrees that if it makes any future change to this Arbitration Agreement (other than a change to the Notice Address) while you are a User of the Services, you may reject any such change by sending HelioPen written notice within thirty (30) calendar days of the change to the Notice Address provided above.  By rejecting any future change, you are agreeing that you will arbitrate any dispute between us in accordance with the language of this Arbitration Agreement as of the date you first accepted these Terms (or accepted any subsequent changes to these Terms).</p></li>

</ol>
</p></li>

<li><p><strong>NOTICE FOR CALIFORNIA USERS</strong><br>
Notice for California Users Under California Civil Code Section 1789.3, Users of the Services from California are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted (a) via email at <a href="mailto:info@HelioPen.ai" class="text-dark"> dca@dca.ca.gov</a>; (b) in writing at: Department of Consumer Affairs, Consumer Information Division, 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834; or (c) by telephone at (800) 952-5210 or (800) 326-2297 (TDD).  Sacramento-area consumers may call (916) 445-1254 or (916) 928-1227 (TDD).  You may contact us at HelioPen, Inc, 249 S Highway 101 #220, Solana Beach, CA 92075.</p></li>

<li><p><strong>SEVERABILITY</strong><br>
If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect.</p></li>

<li><p><strong>ASSIGNABILITY</strong><br>
You may not assign the Terms without the prior written consent of HelioPen, but HelioPen may assign or transfer these Terms, in whole or in part, without restriction.</p></li>

<li><p><strong>GOVERNING LAW</strong><br>
These Terms will be governed by the laws of the State of California without regard to its conflict of law provisions.  With respect to any disputes or claims not subject to arbitration, as set forth above, you and HelioPen submit to the personal and exclusive jurisdiction of the state and federal courts located within San Francisco County, California.  </p></li>

<li><p><strong>MISCELLANEOUS</strong><br>
These Terms constitute the entire agreement between you and HelioPen and govern your use of the Services, HelioPen Pass NFTs, NFT IP and Perks, superseding any prior agreements between you and HelioPen with respect thereto.  Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not intended to confer third party beneficiary rights upon any other person or entity.  You also may be subject to additional terms and conditions that may apply when you use affiliate or third party services, third party content or third party software.  The failure of HelioPen to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.  You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Services, any HelioPen Pass NFT, any Perks or these Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred.  A printed version of this agreement and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.  The section titles in these Terms are for convenience only and have no legal or contractual effect.  Notices to you may be made via either email or regular mail.  HelioPen may also provide notices to you of changes to these Terms or other matters by displaying notices or links to notices generally on the Services.</p></li>

</ol>







                                </div>
                            </div>
                        </div>
                    </section>


</template>

<script>

export default {
  name: 'AppTerms',
};

</script>