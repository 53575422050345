import { createRouter, createWebHistory } from 'vue-router';
import MainContent from '@/views/MainContent.vue';
import PaymentForm from '@/views/PaymentForm.vue';
import Privacy from '@/views/Privacy.vue';
import Terms from '@/views/Terms.vue';

const routes = [
  {
    path: '/',
    name: 'MainContent',
    component: MainContent
  },
  {
    path: '/secondary',
    name: 'PaymentForm',
    component: PaymentForm
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
    scrollBehavior(to) {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: "smooth"
        };
      }
    }
  });
  
export default router;
