<template>
     <section class="home-section hero-section" id="section_1">
                <div class="section-overlay"></div>

                <div class="container d-flex justify-content-center align-items-center">
                    <div class="row">

      <div class="col-12 mt-auto mb-5 text-center">
    <h2
      class="text-white mb-5 display-1 fw-bold ls-title"
      data-aos="fade-up"
      ref="title"
    ></h2>
    <h5
      class="text-white mb-5 fs-4 fw-normal ls-text"
      data-aos="fade-up"
      data-aos-delay="100"
      ref="subtitle1"
    ></h5>
    <h5
      class="text-white mb-5 fs-4 fw-normal ls-text"
      data-aos="fade-up"
      data-aos-delay="100"
      ref="subtitle2"
    ></h5>
                               <a class="btn custom-btn smoothscroll" data-aos="fade-up" data-aos-delay="260" href="#section_2">Let's begin</a>

  </div>

                        <div class="col-lg-12 col-12 mt-auto d-flex flex-column flex-lg-row text-center" data-aos="fade-up" data-aos-delay="200" >
                            <div class="date-wrap">
                                <h5 class="text-white">
                                    <i class="custom-icon bi-clock me-2"></i>
                                        From 28<sup>th</sup>,March 2023,Pass on sale.
                                </h5>
                            </div>

                            <div class="location-wrap mx-auto py-3 py-lg-0">
                                <h5 class="text-white">
                                    
                                   
                                </h5>
                            </div>

                            <div class="social-share" data-aos="fade-up" data-aos-delay="150">
                                <ul class="social-icon d-flex align-items-center justify-content-center">
                                    <span class="text-white me-3">Share:</span>

                                    <li class="social-icon-item">
                                        <a href="https://discord.gg/kk298yPn4M" class="social-icon-link">
                                            <span class="bi-discord"></span>
                                        </a>
                                    </li>

                                    <li class="social-icon-item">
                                        <a href="https://twitter.com/HelioPenAi" class="social-icon-link">
                                            <span class="bi-twitter"></span>
                                        </a>
                                    </li>

                                    <!-- <li class="social-icon-item">
                                        <a href="#" class="social-icon-link">
                                            <span class="bi-instagram"></span>
                                        </a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="about-section section-padding" id="section_2">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-12 mb-4 mb-lg-0 d-flex align-items-center">
                <div class="services-info">
                    <h2 class="text-white mb-4" data-aos="fade-up" data-aos-delay="">Online Training</h2>
                    <p class="text-white" data-aos="fade-up" data-aos-delay="50">Online training function specially designed for Web3 projects, allowing you to easily train AI models and quickly generate content. One-click generation of drawings without any programming! One-click generation of NFTs.</p>
                    <h6 class="text-white mt-4" data-aos="fade-up" data-aos-delay="100">Style Customization</h6>
                    <p class="text-white" data-aos="fade-up" data-aos-delay="150">Want to add a specific style to your creations? Our style customization function allows you to easily choose from a variety of styles to better meet your needs. Whether it's adding special layouts to articles or adding creativity to videos, multiple needs are easily met.</p>
                    <h6 class="text-white mt-4" data-aos="fade-up" data-aos-delay="200">Model Repository</h6>
                    <p class="text-white" data-aos="fade-up" data-aos-delay="260">Want more choices? The model repository stores a variety of AI models to meet various creative needs. Simply search and find the model that best fits your creative type, making your creations easier.</p>
                </div>
            </div>
            <div class="col-lg-6 col-12" data-aos="fade-up" data-aos-delay="100">
                <div class="about-text-wrap" >
                    <img src="images/pexels-alexander-suhorucov-1.jpg" class="about-image img-fluid" data-aos="fade-down-left" data-aos-delay="100">
                    <div class="about-text-info d-flex">
                        <div class="d-flex" data-aos="fade-up" data-aos-delay="200">
                            <i class="about-text-icon bi-person"></i>
                        </div>
                        <div class="ms-4">
                            <h6 data-aos="fade-up" data-aos-delay="350">Happy Time</h6>
                            <p class="mb-0" data-aos="fade-up" data-aos-delay="400">Save more time to accompany your family and enjoy the warmth of family and companionship.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="about-section2 section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-12">
                <div class="about-text-wrap">
                    <img src="images/pexels-alexander-suhorucov-2.jpg" class="about-image img-fluid" data-aos="slide-right" data-aos-delay="50">
                    <div class="about-text-info d-flex">
                        <div class="d-flex">
                            <i class="about-text-icon bi-person"></i>
                        </div>
                        <div class="ms-4">
                            <h6 data-aos="fade-up" data-aos-delay="200">AI Leads Social Innovation, Creating a Brilliant Era Together</h6>
                            <p class="mb-0" data-aos="fade-up" data-aos-delay="350">Artificial intelligence brings revolutionary changes to human society, and let us witness the great achievements of this era together.</p>
                        </div>
                    </div>
                </div>
            </div>
                    <div class="col-lg-6 col-12 mb-4 mb-lg-0 d-flex align-items-center">
            <div class="services-info">
                <h2 class="text-white mb-4" data-aos="fade-up" data-aos-delay="50">Video Generation</h2>
                <p class="text-white" data-aos="fade-up" data-aos-delay="100">Professional video production function, convenient for producing various types of videos, including advertisements, promotional videos, educational videos, etc. One-click generation of NFTs.</p>
                <h6 class="text-white mt-4" data-aos="fade-up" data-aos-delay="150">Infinite Drawing Board</h6>
                <p class="text-white" data-aos="fade-up" data-aos-delay="200">If you are a painting enthusiast, our infinite drawing board and line art coloring function will definitely make you love it. You can create freely on the infinite drawing board, and also use the line art coloring function to color quickly.</p>
                <h6 class="text-white mt-4" data-aos="fade-up" data-aos-delay="260">Line Art Coloring</h6>
                <p class="text-white" data-aos="fade-up" data-aos-delay="300">Automatic coloring function that meets various painting needs, adding color to your artwork. Just upload the line art and our platform will automatically complete the coloring.</p>
            </div>
        </div>
    </div>
</div>
</section>

<section class="about-section3 section-padding" >
                <div class="container">
                    <div class="row">
                                          <div class="col-lg-6 col-12 mb-4 mb-lg-0 d-flex align-items-center">
                        <div class="services-info">
                           <h2 class="text-white mb-4" data-aos="fade-up" data-aos-delay="">Text Generation</h2>

                            <p class="text-white" data-aos="fade-up" data-aos-delay="50">We provide you with high-quality text generation services that make it easy for you to write articles, blogs, novels, emails, and chat messages, inspiring your creativity.</p>

                            <h6 class="text-white mt-4" data-aos="fade-up" data-aos-delay="100">One-Click Media Content Generation</h6>

                            <p class="text-white" data-aos="fade-up" data-aos-delay="150">Quickly generate media content for your video. We combine text generation, images (or 3D characters), voice (characters can speak), and music to automatically create eye-catching content that can spread quickly.</p>

                            <h6 class="text-white mt-4" data-aos="fade-up" data-aos-delay="200">Continuous Optimization and Update</h6>

                            <p class="text-white" data-aos="fade-up" data-aos-delay="260">Continuous optimization and updates will enjoy constantly updated rich functions. We will constantly optimize and add new features based on user feedback, voting, and demand to meet your development needs!</p>
                         </div>
                    </div>

                    <div class="col-lg-6 col-12">
                        <div class="about-text-wrap" >
                            <img src="images/pexels-alexander-suhorucov-3.jpg" class="about-image img-fluid" data-aos="fade-down-left" data-aos-delay="100">

                            <div class="about-text-info d-flex">
                                <div class="d-flex">
                                    <i class="about-text-icon bi-person"></i>
                                </div>


                                <div class="ms-4">
                                    <h6 data-aos="fade-up" data-aos-delay="350">Exploration, the eternal dream of humanity.</h6>

                                    <p class="mb-0" data-aos="fade-up" data-aos-delay="500">Through the power of AI technology, we will explore new worlds and create miracles of the future.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

            
<section class="pricing-section section-padding section-bg" id="section_3">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-12 mx-auto">
              <h2 class="text-center mb-4" data-aos="fade-down" data-aos-delay="50">Plans, you' love</h2>
            </div>

        <div class="col-lg-6 col-12">
            <div class="pricing-thumb">
                <div class="d-flex">
                    <div>
                        <h3 data-aos="fade-right" data-aos-delay="100"><small>🚀 Early Bird</small> 0.15 ETH</h3>

                        <p data-aos="fade-right" data-aos-delay="150">Including good things:</p>
                    </div>

                    <p class="pricing-tag ms-auto">Save up to <span>90%</span></p>
                </div>

                <ul class="mt-3 text-sm">
                    <li class="pricing-list-item d-block mb-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="600">🌐 The world's first AI + Web3 content creation platform</li>

                    <li class="pricing-list-item d-block mb-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">💡 High-quality, reliable AI-generated content services!</li>

                    <li class="pricing-list-item d-block mb-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="600" data-aos-delay="400">🌟 Lucrative investment returns! Share in the Web3 revolution benefits!</li>

                    <li class="pricing-list-item d-block mb-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="600" data-aos-delay="600">⏳ Limited spots, buy now to kickstart a new chapter in AI content creation!</li>
                </ul>

                <router-link to="/secondary" class="link-fx-1 color-contrast-higher mt-4"  >
                    <span>Mint Pass</span>
                    <svg class="icon" viewBox="0 0 32 32" aria-hidden="true"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><circle cx="16" cy="16" r="15.5"></circle><line x1="10" y1="18" x2="16" y2="12"></line><line x1="16" y1="12" x2="22" y2="18"></line></g></svg>
                </router-link>
            </div>
        </div>

              <div class="col-lg-6 col-12 mt-4 mt-lg-0">
              <div class="pricing-thumb">
                <div class="d-flex flex-column align-items-center">
                <h3 class="aos-init aos-animate mb-3 text-center" data-aos="fade-up-left" data-aos-duration="600" style="font-weight: bold;">PASS Membership Card</h3>
                <p class="aos-init aos-animate" data-aos="fade-up" data-aos-duration="600" data-aos-delay="100" style="font-size: 1rem;">We are committed to providing users with the best AIGC platform services to help you easily complete various creative tasks.</p>
                <p class="aos-init aos-animate" data-aos="fade-up" data-aos-duration="600" data-aos-delay="200" style="font-size: 1rem;">Enjoy the diverse services of the HelioPen platform for free</p>
                <p class="aos-init aos-animate" data-aos="fade-up" data-aos-duration="600" data-aos-delay="300" style="font-size: 1rem;">Get early access to new features and exclusive updates</p>
                <p class="aos-init aos-animate" data-aos="fade-up" data-aos-duration="600" data-aos-delay="400" style="font-size: 1rem;">Participate in NFT airdrops from time to time</p>
                <p class="aos-init aos-animate" data-aos="fade-up" data-aos-duration="600" data-aos-delay="500" style="font-size: 1rem;">Hold the platform's token airdrop and voting rights</p>
                <p class="aos-init aos-animate" data-aos="fade-up" data-aos-duration="600" data-aos-delay="700" style="font-size: 1rem;">Invite friends to join and receive double rich referral rewards</p>
                <p class="aos-init aos-animate" data-aos="zoom-in-up" data-aos-duration="600" data-aos-delay="800" style="font-size: 1rem; font-weight: bold;">Buy a PASS membership card now to unlock more privileges and discounts!</p>
                <router-link to="/secondary" class="link-fx-1 color-contrast-higher mt-4 aos-init aos-animate">
                <span>Mint Pass</span>
                <svg class="icon" viewBox="0 0 32 32" aria-hidden="true">
                    <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                        <circle cx="16" cy="16" r="15.5"></circle>
                        <line x1="10" y1="18" x2="16" y2="12"></line>
                        <line x1="16" y1="12" x2="22" y2="18"></line>
                    </g>
                </svg>
            </router-link>
        </div>
              </div>
            </div>


          </div>
       </div>
     </section>
 


  <section class="question-section bg-light py-5" id="section_4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h2 class="text-white text-center mb-5" data-aos="fade-right" data-aos-delay="50">HelioPen PASS FAQ</h2>
          <div class="accordion shadow-lg" id="accordionExample">
            <div v-for="(item, index) in items" :key="index" class="accordion-item custom-accordion-item" data-aos="fade-right" data-aos-delay="100">
              <h2 class="accordion-header" :id="'heading' + index">
                <button class="accordion-button collapsed custom-accordion-button" :data-bs-target="'#collapse' + index" :aria-expanded="activeIndex === index" :aria-controls="'collapse' + index" @click="toggle(index)">
                  {{ item.question }}
                </button>
              </h2>
              <div :id="'collapse' + index" class="accordion-collapse collapse custom-accordion-collapse" :class="{ 'show': activeIndex === index }" :aria-labelledby="'heading' + index" data-bs-parent="#accordionExample"  >
                <div class="accordion-body custom-accordion-body">
                  {{ item.answer }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
           
</template>

<script>
export default {
  name: 'mainContent',
    data() {
   return {
activeIndex: -1, // Used to store the current active accordion panel index
items: [
{
question: "What is HelioPen PASS?",
answer: "HelioPen PASS is a membership card product launched by the HelioPen platform. By purchasing a HelioPen PASS membership card, you will enjoy a range of exclusive privileges and benefits, including: Pass members-only features, NFT airdrops, platform token airdrops, voting rights, and free use of various platform services, among others. These will enhance your experience in the world of Muse AI. Furthermore, HelioPen PASS membership cards will continuously add new privileges and benefits, allowing you to engage more deeply in our ecosystem and enjoy the new experiences and opportunities brought by Web3."
},
{
question: "How to buy HelioPen PASS?",
answer: "Click the 'Mint Pass' button on the Pass platform homepage and follow the prompts to complete the purchase."
},
{
question: "What privileges does the HelioPen PASS membership card offer?",
answer: "The HelioPen PASS membership card on the Muse AI platform is your key to enter our member community and unlock new privileges and benefits. By purchasing a HelioPen PASS membership card, you will gain a range of exclusive privileges and discounts, including: Pass members-only features, NFT airdrops, token airdrops, voting rights, and free use of various platform services, among others. In addition, the HelioPen PASS membership card will provide you with more advanced, professional model repositories and one-click generation services, allowing you to quickly and accurately generate various types of content and improve your work efficiency.This will make your creation and experience on the Muse AI platform more enjoyable and efficient."
},
{
question: "How to use the HelioPen PASS membership card?",
answer: "After purchasing the HelioPen PASS membership card, log in to the HelioPen platform to enjoy the various privileges of the HelioPen PASS membership card."
},
{
question: "What is the validity period of the HelioPen PASS membership card?",
answer: "The HelioPen PASS membership card has a lifetime validity."
},
{
question: "How to contact Pass platform customer service?",
answer: "You can open a ticket on Discord, fill in the relevant information and submit it, then wait for our reply."
},
{
question: "After purchasing the HelioPen PASS membership card, how do I receive token airdrops?",
answer: "After purchasing the HelioPen PASS membership card, we will regularly conduct token airdrops. Your rewards will be based on multiple dimensions such as your transaction count on the platform (the more transactions, the more contribution value, and the more airdrops), holdings, and usage."
},
{
question: "How do I receive NFT airdrops?",
answer: "NFT airdrops are a privilege offered by the Pass platform for HelioPen PASS membership cardholders. We will regularly conduct NFT airdrops."
},
{
question: "Can the HelioPen PASS membership card be refunded?",
answer: "Refund service is not supported after the successful purchase of the HelioPen PASS membership card. Please carefully read the product introduction and consider your decision before purchasing."
},
{
question: "Can the HelioPen PASS membership card be transferred?",
answer: "The HelioPen PASSmembership card can be transferred and sold on any platform that supports NFT sales."
},
{
question: "Which cryptocurrency is required to purchase the HelioPen PASS membership card?",
answer: "To purchase the HelioPen PASS membership card, you need to use Ethereum (ETH) for payment. Please ensure that your Ethereum wallet has a sufficient ETH balance."
}
],
};
},
 mounted() {
    this.typeWriterEffect(this.$refs.title, "HELIOPEN");
    this.typeWriterEffect(this.$refs.subtitle1, "Experience one-click generation of diversehigh-quality", 1000);
    this.typeWriterEffect(this.$refs.subtitle2, "and trustworthy AIGC services, and bid farewell to the tedious work of content creation", 2000);
  },
methods: {
toggle(index) {
// If the current index is the same as the active index, set activeIndex to -1, otherwise set it to the new index.
this.activeIndex = this.activeIndex === index ? -1 : index;
},

typeWriterEffect(element, text, delay = 0) {
      setTimeout(() => {
        let i = 0;
        function typeWriter() {
          if (i < text.length) {
            element.innerHTML += text.charAt(i);
            i++;
            setTimeout(typeWriter, 30);
          }
        }
        typeWriter();
      }, delay);
      },
},
};
</script>
<style scoped>
.custom-accordion-item {
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1rem;
}

.custom-accordion-button {
  font-weight: 600;
  background-color: #f8f9fa;
  border: none;
  color: #333;
  transition: background-color 0.3s, transform 0.3s;
}

.custom-accordion-button:hover {
  background-color: #e9ecef;
  transform: scale(1.02);
}

/* .custom-accordion-button:not(.collapsed)::after {
  content: "-";
}

.custom-accordion-button.collapsed::after {
  content: "+";
} */

.custom-accordion-collapse {
  border-top: 1px solid #dee2e6;
}

.custom-accordion-body {
  font-size: 16px;
  line-height: 1.6;
  padding: 1rem 2rem;
}

.section-padding {
  padding: 3rem 0;
}

.custom-card {
  border: none;
  background: #fff;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.custom-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.2);
}

.fa {
  vertical-align: middle;
}

.carousel-container {
  max-width: 800px;
  margin: auto;
}

.artists-thumb {
  position: relative;
}

.artists-image-wrap {
  overflow: hidden;
  height: 350px;
}

.artists-image {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
 



</style>
